import React from 'react'
import { mount, route, withView, redirect, lazy, map, compose } from 'navi';
import LoginPage from '../Pages/LoginPage';
import Layout from '../Layout';
import { View } from 'react-navi';
import ChatBox from '../Pages/Dashboard/ChatBox';
import LaunchPage from '../Pages/LaunchPage';

export default compose(
    withView((request, context, args) => <Layout currentUser={context.currentUser} authService={context.authService}>
        <View />
        {context.currentUser && <ChatBox currentUser={context.currentUser} authService={context.authService} />}
    </Layout>),
    mount({
        '/login': map((request, context) => context.currentUser ? redirect('/hr')
            : route({
                title: 'Login Page',
                view: <LoginPage currentUser={context.currentUser} authService={context.authService} />
            })),
            '/': map((request, context) => {
                if (!context.currentUser) {
                    return redirect('/login')
                }
                return redirect('/hr')
            }),
            '/launch': map((request, context) => 
            route({
                title: 'Login Page',
                view: <LaunchPage />
            })),
            
        '/hr': lazy(() => import('./DashboardRoutes')),

    })
)