import React, { useState } from 'react'
import { useNavigation } from 'react-navi'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Typography, TextField, Button } from '@material-ui/core';
import { PostData } from '../Service/service';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
    },
    fontLarge: {
        fontSize: '9em',
        fontWeight: 'bold',
        color: 'black'
    }
}))

export default function LoginPage({ currentUser, authService }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    let navigation = useNavigation();



    function onSubmit(e) {
        e.preventDefault();

        if (username != '' && password != '') {
             // login and flush the error
      setIsLoading(true)

      PostData('/login', { email: username, password: password }).then(
        (resp) => {
          authService.setTokenAfterLogin(resp).then((resp) => {
            setIsLoading(false)
            if (resp == true) {
              navigation.navigate('/hr').then(() => {
                window.location.reload()
              })
            } else {
              setError(resp)
            }
          })
        },
      ).catch((err) => {
        setIsLoading(false)
      })

            
            
        } else {
            setError('Please insert all fields');
        }

    }
    return <React.Fragment>
        <div className={classes.root}>
            <div align="center">


            <img
                    src={require('./../assets/login-bg.jpg')}
                    style={{ width: '70%' }}
                />
                {/* <Typography
                    variant="h1"
                    className={classes.fontLarge}
                    gutterBottom
                >
                    HR
                </Typography> */}


                {error != '' ? <Typography style={{color: 'red'}} variant="caption">{JSON.stringify(error)}</Typography> : null}

                <form onSubmit={onSubmit}>
                    <div align="left" style={{ width: '70%' }}>
                        <TextField
                            required
                            error={Boolean(error)}
                            fullWidth
                            label="Username/Email"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />

                        <TextField
                            required
                            type="password"
                            fullWidth
                            error={Boolean(error)}
                            label="Password"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />

                        <div align="right">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isLoading}
                            >
                                Login
                            </Button>
                        </div>

                    </div>
                </form>


            </div>

        </div>
    </React.Fragment>
}