import { NOTIFICATION_ON, NOTIFICATION_OFF, LOAD_PARTY, ADD_PARTY, EDIT_PARTY, ACCESS, TOGGLE_COLLAPSE, GET_MY_DETAILS, GET_MY_LEAVE, GET_MY_LEAVE_BALANCE, GET_HOLIDAY, GET_MY_ATTENDANCE, SOCKET_ON, CHATBOX, GET_MESSAGE, GET_PAYHEAD, GET_SESSION} from "../constants/action-types";

const initialState = {
    notification_type: '',
    notification_status: '',
    notification_text: '',
    notification_action:false,
    notification_link:'',
    selectedKeys: ['home'],


    collapse: false,
    drawerWidth: 120,

    myDetails: {},
    isMyDetailsLoaded: false,

    leave_balance:[],
    isLeaveBalanceLoaded:false,

    my_attendance:[],
    isAttendanceLoaded:false,

    my_leave:[],
    isMyLeaveLoaded:false,

    holiday:[],
    isHolidayLoaded:false,

    notice:[],
    isNoticeLoaded:false,

    session: [],
    isSessionLoaded: false,

    grievance:[],
    isGrievanceLoaded:false,

    my_grievance:[],
    isMyGrievanceLoaded:false,
    socket: {},
    chatBox:false,
    message:[],
    isMessageLoaded:false,
    payhead: [],
    isPayheadLoaded: false


};
function rootReducer(state = initialState, action) {



    if (action.type === NOTIFICATION_ON) {
        return Object.assign({}, state, {
            notification_type: action.payload.type,
            notification_status: action.payload.status,
            notification_text: action.payload.text,
            notification_action: action.payload.action,
            notification_link: action.payload.link
        });
    }

    if (action.type === NOTIFICATION_OFF) {
        return Object.assign({}, state, {
            notification_type: '',
            notification_status: '',
            notification_text: '',
            notification_action:false,
            notification_link:''
        });
    }


   
    if (action.type === GET_MY_DETAILS) {
        return Object.assign({}, state, {
            myDetails: action.payload,
            isMyDetailsLoaded: true
        });
    }

    if (action.type === GET_MY_ATTENDANCE) {
        return Object.assign({}, state, {
            my_attendance: action.payload,
            isAttendanceLoaded: true
        });
    }


    if (action.type === GET_MY_LEAVE_BALANCE) {
        return Object.assign({}, state, {
            leave_balance: action.payload,
            isLeaveBalanceLoaded: true
        });
    }


     //Session
 if (action.type === GET_SESSION) {
        
    return Object.assign({}, state, {
        session: action.payload,
        isSessionLoaded: true
    });
}

    if (action.type === GET_MY_LEAVE) {
        return Object.assign({}, state, {
            my_leave: action.payload,
            isMyLeaveLoaded: true
        });
    }

    if (action.type === GET_HOLIDAY) {
        return Object.assign({}, state, {
            holiday: action.payload,
            isHolidayLoaded: true
        });
    }

    if (action.type === GET_MESSAGE) {
        return Object.assign({}, state, {
            message: action.payload,
            isMessageLoaded: true
        });
    }

     //Payhead
 if (action.type === GET_PAYHEAD) {
        
    return Object.assign({}, state, {
        payhead: action.payload,
        isPayheadLoaded: true
    });
}


    if (action.type === SOCKET_ON) {
        return Object.assign({}, state, {
            socket: action.payload
        });
    }

    if (action.type === CHATBOX) {
        return Object.assign({}, state, {
            chatBox: action.payload
        });
    }

    //do not delete
    if(action.type === TOGGLE_COLLAPSE){
        let dw = 200;
        if(state.collapse){
            dw = 80;
        }else{
            dw = 200
        }

        if(action.payload === null){
            return Object.assign({}, state, {
                collapse: !state.collapse,
                drawerWidth: dw
             });
        }else{
            return Object.assign({}, state, {
                collapse: action.payload,
                drawerWidth: dw
             });
        }
        
    }



    return state;
}
export default rootReducer;