import { Button } from 'antd'
import React from 'react'
import LaunchIcon from '@material-ui/icons/Launch';
import styles from './launchpage.module.css'

export default function LaunchPage() {

    const [isLaunching, setIsLaunching] = React.useState(false);

    return (
        <>
            <div style={{ width: "100%", position: 'relative', height: "100vh", background: `url('/images/NEHHDC_l.png')`, backgroundSize: "fill", backgroundRepeat: "none", backgroundAttachment: 'fixed' }}>

                <div className={styles.buttonNewLaunchDiv}>
                    <Button icon={<LaunchIcon />} href="https://hrms.nehhdcmis.in/" className={styles.buttonNewLaunch} size="large" type='primary'>&nbsp;Launch Now</Button>
                </div>
            </div>
        </>


    )
}
